import React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./Goods.module.scss";
import basketStyles from "../Basket.module.scss";
import { ReactComponent as ReactLogoName } from "images/profile/profile-name.svg";
import { ReactComponent as DateLogo } from "images/profile/profile-calendar.svg";
import { ActionCreator } from "reducer/basket/basket";
import { ActionCreator as PaymentActionCreator } from "reducer/payment/payment";
import {
  getPromocodeErrorByType,
  getPromocodeSaleByType,
} from "reducer/payment/selectors";
import { getDeliveryRegions, getEventGoodsById, getFormats, getSizesById } from "reducer/data/selectors";
import { Operation } from "reducer/payment/payment";
import { Operation as BasketOperation } from "reducer/basket/basket";
import { Operation as TicketOperation } from "../../../reducer/tickets/tickets";
import { ActionCreator as DataActionCreator, Operation as DataOperation } from "reducer/data/data";

import { ReactComponent as SaleLogo } from "images/basket/promocode.svg";
import { ReactComponent as CheckLogo } from "images/basket/check.svg";
import { ReactComponent as WarnLogo } from "images/basket/warnlogo.svg";

import Message from "components/Auth/Registration/Message/Message";
import OptionalCard from "../OptionalCard/OptionalCard";

import { BASKET_COUNT } from "const/const";
import { updateLocalStorage } from "utils/utils";
import { getTotalCost, setCostItem, setDiscountPrice } from "utils/goods";
import { getAllTickets } from "reducer/tickets/selectors";
import Input from "components/Forms/Input/Input";
import Datepicker from "components/Forms/Datepicker/Datepicker";
import InputPhone from "components/Forms/Input/InputPhone";
import Radio from "components/Forms/Radio/Radio";
import { useValidationText } from "customHooks/useValidation";
import { useValidationPhone } from "customHooks/useValidationPhone";
import { getDate, useValidationDate } from "customHooks/useValidationDate";
import CustomSelect from "components/Calendar/Select/Select";
import { convertBase64 } from "utils/convertBase64";
import { setCount } from "mocks/calendarOptions";
import { getInsurancesList } from "reducer/basket/selectors";
import { InsuranceForm } from "./InsuranceForm/InsuranceForm";
import "../../Calendar/Select/Select.scss";
import { searchDeliveryRegion } from "utils/data";
import { getActualGoodsUpdateData } from "../Basket";
import { getDelivery } from "reducer/basket/selectors";

function checkAgeLimit(birthDate, ageMin = null, ageMax = null, startTime) {
  const birth = new Date(birthDate);
  const startDate = new Date(startTime * 1000);
  let age = startDate.getFullYear() - birth.getFullYear();

  const monthDifference = startDate.getMonth() - birth.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && startDate.getDate() < birth.getDate())) {
    age--;
  }

  if (ageMin !== null) {
    return age <= ageMin;
  }

  if (ageMax !== null) {
    return age >= ageMax;
  }

}

const Goods = React.memo(({ item, handleChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isPreregistration = item.preregistration;
  const itemRegions = item.regions ?? null;
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const formatCluster = item.clusters ?? [];
  const [userObj] = useState(localStorage.getItem("userObj") ? JSON.parse(localStorage.getItem("userObj")) : {});
  const regions = useSelector((state) => getDeliveryRegions(state)) ?? [];
  const [promocodeApplied, setPcApplied] = useState(false);
  const [cluster, setCluster] = useState(null);
  const [isLoadFile, setIsLoadFile] = useState(false);
  const [file, setFile] = useState(null);
  const [isErrors, setErrors] = useState({
    date: ``,
    phone: ``,
    gender: ``,
    file: "",
    size: "",
    region: "",
    license: ""
  });
  const [document, setDocument] = useState(null);
  const [licenseFile, setLicenseFile] = useState(null);
  const [license, setLicense] = useState(null);
  const [region, setRegion] = useState(null);
  const [additionalValue, setAdditionalValue] = useState({});
  const promocodeRef = useRef();
  const promocode = useSelector((state) => getPromocodeSaleByType(state, item));
  const promocodeError = useSelector((state) =>
    getPromocodeErrorByType(state, item)
  );
  const delivery = useSelector((state) => getDelivery(state)) ?? [];
  const insurancesList = useSelector((state) => getInsurancesList(state)) ?? [];
  const sizesById = useSelector((state) => getSizesById(state, `${item.event?.public_id}${item.event_city?.public_id}`));
  const fields = item.additional_fields || [];
  const sizesTshirt = sizesById?.sizes || [];
  const eventGoods = useSelector((state) => getEventGoodsById(state, item.event_city?.public_id)) ?? null;;
  const allTickets = useSelector((state) => getAllTickets(state));
  const formats = useSelector((state) => getFormats(state));
  const userName = item.info?.first_name || userObj?.name || "";
  const userLastName = item.info?.last_name || userObj?.last_name || "";
  const userBirthDate = item.info?.birth_date || userObj?.birth_date && getDate(userObj.birth_date * 1000) || "";
  const userSecondName = item.info?.second_name || userObj?.second_name || "";
  const [gender, setGender] = useState(item.info?.gender || userObj?.gender || `none`);
  const [size, setSize] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const [place, setPlace] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredRegions, setFilteredRegions] = useState([]);
  const nameRef = useRef(),
    telRef = useRef(),
    lastNameRef = useRef(),
    radioRef = useRef(), secondNameRef = useRef();
  const [isSecondName, setIsSecondName] = useState(item.insurance || item.second_name);
  const [
    name,
    isValidName,
    nameError,
    setNameError,
    handleNameChange,
    handleNameFocus,
    setNameValue,
  ] = useValidationText(userName);

  const [valueDate, handleDateChange, date, isValidDate, setValueDate] =
    useValidationDate(userBirthDate);

  const [
    lastName,
    isValidLastName,
    lastNameError,
    setLastNameError,
    handleLastNameChange,
    handleLastNameFocus,
    setLastName,
  ] = useValidationText(userLastName);

  const [
    secondName,
    isValidSecondName,
    secondNameError,
    setSecondNameError,
    handleSecondNameChange,
    handleSecondNameFocus,
    setSecondName,
  ] = useValidationText(userSecondName);

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      const document = await convertBase64(e.target.files[0]);
      setDocument(document);
    }
    setFile(e.target.files[0]);
    setErrors({ ...isErrors, file: '' });
  };

  const handleLicenseChange = async (e) => {
    if (e.target.files[0]) {
      const document = await convertBase64(e.target.files[0]);
      setLicense(document);
    }
    setLicenseFile(e.target.files[0]);
    setErrors({ ...isErrors, license: '' });
  };

  const handleFocus = (evt) => {
    const { name } = evt.target;
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };
  const handleFileFocus = () => setErrors({ ...isErrors, file: '' });
  const handleLicenseFocus = () => setErrors({ ...isErrors, license: '' });
  const handleClusterResetError = () =>
    setErrors((prev) => ({ ...prev, cluster: "" }));
  const handleRadioChange = (evt) => {
    setErrors((prev) => ({ ...prev, gender: "" }));
    setGender(evt.target.value);
  };
  const handleDateFocus = () => setErrors({ ...isErrors, date: '' });

  const handleSelectInsurance = (event) => {
    if (JSON.parse(event.target.value)) {
      const selectedInsurance = JSON.parse(event.target.value);
      setSelectedInsurance(selectedInsurance);
      updateLocalStorage(`basketGoods`, `add`, {
        ...selectedInsurance,
        selector: "insurance",
        count: item.count,
        totalCost: item.count * selectedInsurance.price,
        format_public_id: selectedInsurance.public_id,
        format_id: item.format_public_id
      });
      dispatch(ActionCreator.addGoods({
        ...selectedInsurance,
        selector: "insurance",
        count: item.count,
        totalCost: item.count * selectedInsurance.price,
        format_public_id: selectedInsurance.public_id,
        format_id: item.format_public_id
      }));
    }
    const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
    dispatch(BasketOperation.updateUserBasketGoods(getActualGoodsUpdateData(goods), () => { }));
  };
  const [phone, isValidTel, handlePhoneChange, , setPhone] = useValidationPhone(
    item.info?.phone && `+${item.info.phone}` || userObj?.phone && `+${userObj?.phone}`
  );
  const isCountSizes = sizesTshirt.length > 0 && !!sizesTshirt.find((el) => el.count > 0);

  const usedTicket = allTickets.find(
    (el) =>
      el.event_format.public_id === item.public_id && item.selector === "ticket"
  );

  const isVipUser = userObj?.role && typeof userObj?.role !== "string" && userObj.role.find((el) => el === "vip"); // Проверка относится ли user к категории vip

  useEffect(() => {
    if (regions?.length && item.info?.region && !region) {
      setRegion(searchDeliveryRegion(regions, item.info.region, "region"));
    }
  }, [regions]);

  useEffect(() => {
    if (itemRegions?.length || item.info?.region) {
      dispatch(DataOperation.loadDeliveryRegions());
    }
  }, []);

  useEffect(() => {
    let filteredRegions = [];
    if (
      item.regions &&
      item.regions?.length > 0 &&
      regions.length > 0
    ) {
      item.regions?.forEach((el) => {
        filteredRegions.push(searchDeliveryRegion(regions, el, "region"));
      });
    } else if (item.regions) {
      filteredRegions = regions.map((el) => {
        return { id: el.id, label: el.name_ru, value: el.id };
      });
    }
    setFilteredRegions(filteredRegions);
  }, [regions]);

  useEffect(() => {
    if (eventGoods) {
      const newObj = { ...item, eventGoods: eventGoods?.data };

      dispatch(ActionCreator.updateGoods(newObj));
      updateLocalStorage(`basketGoods`, `add`, newObj);
    }
  }, [eventGoods]);

  useEffect(() => {
    if (item.selector === "ticket") {
      dispatch(BasketOperation.loadInsurancesList(item.format_public_id));
    }
  }, []);

  useEffect(() => {
    if (item.info?.item_size && sizesTshirt?.length > 0) {
      const defaultSize = sizesTshirt.find((el) => el.size === item.info.item_size);

      setSize({ label: defaultSize?.size, value: defaultSize?.count });
    } else if (item.defaultSize && sizesTshirt?.length > 0) {
      setSize(item.defaultSize);
    }

  }, [sizesTshirt]);

  useEffect(() => {
    if (item.start_time > Date.now()) {
      deleteGoodsHandler();
    }

  }, []);

  useEffect(() => {
    if (formatCluster.length > 0 && !document && !cluster) {
      const newObj = { ...item, cluster: {}, preReg: "" };

      dispatch(ActionCreator.updateGoods(newObj));
      updateLocalStorage(`basketGoods`, `add`, newObj);
      handleChange();
    }
    if (item.event_city?.public_id && item.event?.public_id) {
      dispatch(
        DataOperation.loadSizeById({
          event_city_public_id: item.event_city?.public_id,
          event_public_id: item.event?.public_id,
        }, setIsLoading)
      );
    }

    return () => dispatch(DataActionCreator.clearSize([]));
  }, []);

  useEffect(() => {
    if (item.cluster?.document) {
      setDocument(item.cluster?.document);
    }
    if (item.file) {
      setFile({ name: item.file });
    }
  }, []);

  useEffect(() => {
    if (formatCluster.length > 0 && item.cluster) {
      const defaultCluster = formatCluster.find((el) => el.value === item.cluster?.name);
      setCluster(defaultCluster);
    }

  }, []);

  useEffect(() => {
    if (formatCluster.length > 0 && cluster) {
      const currentCluster = formatCluster.find((el) => el.value === cluster.value);
      setIsLoadFile((prev) => prev !== currentCluster.file ? currentCluster.file : prev);
    }
  }, [cluster]);

  const handleValidationUpdate = () => {
    let newObj = {
      ...item,
      preReg: false,
    };
    updateLocalStorage(`basketGoods`, `add`, newObj);
    dispatch(ActionCreator.updateGoods(newObj));
    handleChange();
  };

  useEffect(() => {

    if (item.delivery && delivery.length && !deliveryMethod) {

      let newObj = {
        ...item,
        delivery_method: true
      };

      if (item.selector === "shop") {
        const filteredDelivery = delivery?.filter((el) => el?.pickup);

        if (filteredDelivery.length === delivery.length) {
          newObj = {
            ...newObj,
            isHiddenDeliveryAddres: true
          };
        } else {
          newObj = {
            ...newObj,
            isHiddenDeliveryAddres: false
          };
        }
      }

      updateLocalStorage(`basketGoods`, `add`, newObj);
      dispatch(ActionCreator.updateGoods(newObj));
      handleChange();

      setErrors((prev) => ({ ...prev, deliveryMethod: 'Выберите способ доставки' }));

    } else if (item.delivery && delivery.length && deliveryMethod?.value) {
      setErrors((prev) => ({ ...prev, deliveryMethod: '' }));

      const currentDelivery = delivery?.find((el) => el?.public_id === deliveryMethod?.value);

      const newObj = {
        ...item,
        currentPickupSelect: currentDelivery?.pickup,
        delivery_public_id: deliveryMethod.value
      };
      updateLocalStorage(`basketGoods`, `add`, newObj);
      dispatch(ActionCreator.updateGoods(newObj));
      handleChange();
    }

  }, [deliveryMethod, delivery]);

  useEffect(() => {

    if (isPreregistration && !isLoading) {

      let additional_fields = [];
      let sizeError = "";
      let regionError = "";
      let placeError = "";

      if (item.additional_fields && fields.length) {
        additional_fields = fields.map((el) => {
          const name = el.name;
          if (!additionalValue[`${name}`] && el.required) {
            setErrors((prev) => ({ ...prev, [name]: `Заполните поле` }));
          } else {
            setErrors((prev) => ({ ...prev, [name]: `` }));
          }
          return {
            value: additionalValue[name],
            name,
            required: el.required,
          };
        });
      }
      const filteredFields = additional_fields.filter((el) => {
        if (el.required && el?.value?.length) return true;
        if (!el.required) return true;
        return false;
      }) ?? [];

      if (size === null && sizesTshirt.length > 0 && isCountSizes && !item.shirt_unavailable) {
        setErrors((prev) => ({ ...prev, size: `Выберите размер` }));
        sizeError = true;
      } else {
        sizeError = false;
        setErrors((prev) => ({ ...prev, size: `` }));
      }
      if (!isValidName) {
        setNameError(`Заполните поле`);
        handleValidationUpdate();
      } else {
        setNameError("");
      }
      if (item.select && place === null) {
        setErrors((prev) => ({ ...prev, place: 'Выберите место получения стартового номера' }));
        placeError = true;
      } else {
        placeError = false;
        setErrors((prev) => ({ ...prev, place: '' }));
      }
      if (!isValidLastName) {
        setLastNameError(`Заполните поле`);
        handleValidationUpdate();
      } else {
        setLastNameError("");
      }
      if (!isSecondName) {
        setSecondName("");
      }
      if (!region && itemRegions) {
        setErrors((prev) => ({ ...prev, region: `Выберите регион` }));
        handleValidationUpdate();
        regionError = true;
      } else {
        regionError = false;
        setErrors((prev) => ({ ...prev, region: `` }));
      }

      if (!isValidDate) {
        setErrors((prev) => ({ ...prev, date: `Введите корректную дату` }));
        handleValidationUpdate();
      } else {
        setErrors((prev) => ({ ...prev, date: `` }));
      }
      if (!isValidTel) {
        setErrors((prev) => ({ ...prev, phone: `Заполните поле` }));
      } else {
        setErrors((prev) => ({ ...prev, phone: `` }));
      }
      if (gender === `none`)
        setErrors((prev) => ({
          ...prev,
          gender: `Выберите одно из значений`,
        }));

      if (item.age_min && checkAgeLimit(date, item.age_min, null, item.start_time)) {

        setErrors((prev) => ({
          ...prev,
          date: `Участие старше ${item.age_min}`,
        }));
        handleValidationUpdate();
        return;
      }

      if (item.age_max && checkAgeLimit(date, null, item.age_max, item.start_time)) {

        setErrors((prev) => ({
          ...prev,
          date: `Участие младше ${item.age_max}`,
        }));
        handleValidationUpdate();
        return;

      }

      if (formatCluster.length > 0) {
        if (!cluster) {
          setErrors((prev) => ({ ...prev, cluster: 'Выберите кластер' }));
          return;
        } else {
          setErrors((prev) => ({ ...prev, cluster: '' }));
        }
        if (!document && isLoadFile) {
          setErrors((prev) => ({ ...prev, file: 'Выберите файл' }));
          return;
        } else {
          setErrors((prev) => ({ ...prev, file: '' }));
        }
      }

      if (item.license_file && !license) {
        setErrors((prev) => ({ ...prev, license: 'Выберите файл' }));
        return;
      } else {
        setErrors((prev) => ({ ...prev, license: '' }));
      }


      if (isValidName &&
        isValidLastName &&
        isValidDate &&
        isValidTel &&
        gender !== `none` &&
        filteredFields.length === fields.length &&
        !sizeError &&
        !regionError &&
        !placeError) {

        let info = {
          first_name: name,
          last_name: lastName,
          phone: phone?.slice(1),
          gender,
          birth_date: date,
        };


        if (item.regions) {
          if (region) {
            info = {
              ...info,
              region: region.id,
            };
          }
        }

        if (secondName) {
          info = {
            ...info,
            second_name: secondName,
          };
        }
        if (additional_fields?.length > 0) {
          additional_fields.forEach((el) => {
            info[`${el?.name}`] = el?.value;
          });
        }

        if (size && size?.label !== "Футболка не нужна" || item.info?.item_size && size?.label !== "Футболка не нужна" && !item.shirt_unavailable) {
          info = {
            ...info,
            item_size: size?.label || item.info?.item_size,
          };
        } else if (size?.label === "Футболка не нужна") {
          info = {
            ...info
          };
          let newObj = {
            defaultSize: { value: "defaultvalue", label: "Футболка не нужна" },
            ...item,
            info
          };
          delete info?.info?.item_size;
          updateLocalStorage(`basketGoods`, `add`, newObj);
          handleChange();
        }
        let newObj = {
          ...item,
          info,
          preReg: "ok",
        };

        if (item.select && place?.value) {
          newObj = {
            ...newObj,
            select_field: { name: place?.value }
          };
        }

        if (item.license_file && license) {
          newObj = {
            ...newObj,
            license: license
          };
        }

        if (formatCluster.length > 0 && isLoadFile) {
          newObj = {
            ...newObj,
            file: file?.name,
            cluster: {
              name: cluster && cluster.value,
              document
            }
          };
        }
        else if (formatCluster.length > 0 && !isLoadFile) {
          newObj = {
            ...newObj,
            cluster: {
              name: cluster && cluster.value
            }
          };
        }

        updateLocalStorage(`basketGoods`, `add`, newObj);
        dispatch(ActionCreator.updateGoods(newObj));

        handleChange();
      }
    }
  }, [name, place, isLoading, license, isValidDate, region, additionalValue, size, isCountSizes, lastName, secondName, phone, gender, valueDate, date, isSecondName, file, cluster, document, isLoadFile]);

  useEffect(() => {
    if (isVipUser && !allTickets.length) {
      dispatch(TicketOperation.loadTickets());
    }
  }, [isVipUser, allTickets, dispatch]);

  useEffect(() => {
    setPcApplied(!!(promocode && promocode.active));
  }, [promocode, item]);

  // Получаем "продукты" мероприятий, добавленных в корзину
  // и записываем в state (eventGoods)
  // Загружаем форматы билетов, которые находятся в корзине
  // для проверки доступных билетов перед оплатой
  useEffect(() => {
    /*  const format = formats.find((el) => el.public_id === item.public_id); */

    /*  if (item.selector === "ticket") {
       if (format && format.public_id !== item.public_id) { */
    if (item.selector === "ticket") {
      dispatch(
        BasketOperation.checkTicketsBeforePaymentByFormatId(item.public_id)
      );
    }
    /*  } */
    /*  } */
  }, [dispatch]);

  useEffect(() => {
    if (item.delivery) {
      if (item.selector === "shop") {
        dispatch(
          BasketOperation.getDeliveryByItemType(item.type)
        );
      } else {
        dispatch(
          BasketOperation.getDeliveryByItemType(item.selector)
        );
      }

    }

  }, []);

  useEffect(() => {
    if (fields?.length) {
      fields.forEach((field) => {
        setAdditionalValue(prev => ({ ...prev, [field.name]: item.info?.[`${field.name}`] }));
      });
    }
  }, []);

  useEffect(() => {
    if (!eventGoods && item.event_city?.public_id) {
      dispatch(DataOperation.loadEventGoods(item.event_city?.public_id));
    }
  }, []);

  // ф-ция для обновления количества товара в карточке
  // обновляет не только количество, но и общую цену для товара (так было легче считать)
  const basketTicketHandler = (evt) => {
    evt.preventDefault();

    const target = evt.target.dataset.ticketBtn;
    let count = item.count;

    let ticketLeft = item.tickets_left?.athlete;

    if (item.kind) {
      ticketLeft = item.tickets_left?.[item.kind];
    }

    if (promocode?.kind) {
      ticketLeft = item.tickets_left?.[promocode?.kind];
    }

    let min_count = item.buy_limit ? item.buy_limit : BASKET_COUNT.min_count;
    let max_count =
      (item.buy_limit && item.buy_limit) ||
      ticketLeft ||
      BASKET_COUNT.max_count;

    if (item.team_all) {
      min_count =
        item.fixed_count === false ? BASKET_COUNT.min_count : item.max_count;
      max_count = item.max_count * BASKET_COUNT.max_count;
    }
    let totalCost = item.totalCost;

    if (target === `increment`) {
      count = count >= max_count ? max_count : count + min_count;
    } else if (target === `decrement`) {
      count = count <= min_count ? min_count : count - min_count;
    }
    totalCost =
      isVipUser && usedTicket === undefined && item.selector === "ticket"
        ? getTotalCost(
          setDiscountPrice(
            item.price,
            userObj.discount,
            userObj.discount_form
          ),
          1,
          item.insurance && item.insurance_cost
        ) +
        getTotalCost(
          item.price,
          count - 1,
          item.insurance && item.insurance_cost
        )
        : getTotalCost(
          item.price,
          count,
          item.insurance && item.insurance_cost
        );

    let newObj = { ...item, totalCost };

    if (count >= 0) {
      newObj = { ...newObj, totalCost, count };
    }
    dispatch(ActionCreator.updateGoods(newObj));
    updateLocalStorage(`basketGoods`, `add`, newObj);
    const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
    dispatch(BasketOperation.updateUserBasketGoods(getActualGoodsUpdateData(goods), () => { }));
  };
  // удаление товара, как из LS, так и из Store
  const deleteGoodsHandler = () => {
    if (item.public_id === "18f88686a3ae3f4eebf08cfae807a6c58635ac1f") {
      const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
      const medal = goods?.find((el) => el.public_id === "190ba3238ce08da60220668278b2f92c68e967e7");
      if (medal) {
        dispatch(BasketOperation.removeShop(medal));
        dispatch(PaymentActionCreator.removePromocodes(medal)); // del promocode if exists
        dispatch(ActionCreator.removeCurrentGoods(medal));
      }
    }
    else if (item.eventGoods) {
      const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
      item.eventGoods?.forEach((el) => {
        goods.forEach((elem) => {
          if (el?.public_id === elem?.public_id) {
            dispatch(BasketOperation.removeShop(elem));
            dispatch(PaymentActionCreator.removePromocodes(elem)); // del promocode if exists
            dispatch(ActionCreator.removeCurrentGoods(elem));
          }
        });
      });
    }

    dispatch(BasketOperation.removeShop(item));
    dispatch(PaymentActionCreator.removePromocodes(item)); // del promocode if exists
    dispatch(ActionCreator.removeCurrentGoods(item));

    const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
    dispatch(BasketOperation.updateUserBasketGoods(getActualGoodsUpdateData(goods), () => { }));
  };

  const handleSelectChange = (value) => {
    setIsLoadFile((prev) => prev !== value.file ? value.file : prev);
    const newObj = { ...item, cluster: {}, preReg: "" };
    dispatch(ActionCreator.updateGoods(newObj));
    updateLocalStorage(`basketGoods`, `add`, newObj);
    handleChange();

    setCluster(value);
  };

  const handleSizeResetError = () => {
    setErrors((prev) => ({ ...prev, size: `` }));
    /*   dispatch(ActionCreator.registrationMessage("")) */
  };

  const promocodeFocusHandler = () => {
    // delete message
    dispatch(PaymentActionCreator.removePromocodeError(item));
  };

  // применение промокода для карточки товара (если длина value > 0)
  // применяет промокод при успешном вводе и добавляет его в Store
  const handlePromocodeCheck = () => {
    const value = promocodeRef.current.value;

    if (value.length > 0) {
      const codeInfo = { code: value, ...item };

      dispatch(Operation.promocode(codeInfo));

      if (codeInfo.selector === "shop") {
        updateLocalStorage(`basketGoods`, `add`, {
          ...codeInfo,
          promocode: value,
        });
      }
    } else {
      dispatch(
        PaymentActionCreator.setPromocodeError({
          message: `Введите промокод`,
          ...item,
        })
      );
    }
  };

  // смена промокода - по сути удаление из Store
  const handlePromocodeChange = () => {
    dispatch(PaymentActionCreator.removePromocodes(item));
  };

  const handleButtonRemoveInsurance = () => {
    if (!item.second_name) {
      setIsSecondName(false);
    }
    setSecondName(userSecondName);
    handleSecondNameChange({ target: { value: userSecondName } });
    const newObj = {
      ...item,
      insurance: false,
      totalCost: getTotalCost(item.price, item.count),
      preReg: "",
      secondName: userSecondName
    };
    handleChange();
    dispatch(ActionCreator.updateGoods(newObj));
    updateLocalStorage(`basketGoods`, `add`, newObj);
    const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
    dispatch(BasketOperation.updateUserBasketGoods(getActualGoodsUpdateData(goods), () => { }));
  };
  const handleButtonAddInsurance = () => {
    setIsSecondName(true);
    setSecondName(userSecondName);
    handleSecondNameChange({ target: { value: userSecondName } });
    const newObj = {
      ...item,
      insurance: true,
      totalCost: getTotalCost(item.price, item.count, item.insurance_cost),
      preReg: ""
    };
    handleChange();
    dispatch(ActionCreator.updateGoods(newObj));
    updateLocalStorage(`basketGoods`, `add`, newObj);
    if (isSecondName && !isValidSecondName) {
      setSecondNameError(`Заполните поле`);
      return;
    } else {
      setSecondNameError("");
    }
    const goods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));
    dispatch(BasketOperation.updateUserBasketGoods(getActualGoodsUpdateData(goods), () => { }));
  };

  const handleAdditionalFiledFocus = (name) => {
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleAdditionalField = (event) => {
    setAdditionalValue(prev => ({ ...prev, [event.target.name]: event.target.value }));
  };

  return (
    <>
      <li className={styles.item}>
        <div className={styles.logoWrap}>
          <img className={styles.logo} src={item.selector === "insurance" ? item.image : item.logo} alt="Гонка Героев" />
        </div>
        <div className={styles.infoBlock}>
          {item.event && item?.event?.title && (
            <h3 className={styles.infoBlockTitle}>{item.event.title}</h3>
          )}
          <div className={styles.insideItem}>
            <div className={styles.location}>
              <b>
                {item.selector === "shop" || item.selector === "insurance"
                  ? t("basket.good.name1.product")
                  : t("basket.good.name1.location")}
                :
              </b>
              <p className={styles.text}>
                {item.selector === "shop" ? item.title : item.selector === "insurance" ? item.name : item.address}
              </p>
            </div>
            <div className={styles.format}>
              <b>
                {item.selector === "shop"
                  ? item.size
                    ? `${t("basket.good.name2.size")} :`
                    : null
                  : `${t("basket.good.name2.format")} :`}
              </b>
              <p className={styles.text}>
                {item.selector === `shop` ? item.size : item.title}
              </p>
            </div>
            <div className={styles.count}>
              <b>{t("basket.good.count")}:</b>
              <div className={`${styles.text} ${styles.countText}`}>
                <button
                  type="button"
                  data-ticket-btn="increment"
                  className={styles.countBtn}
                  onClick={basketTicketHandler}
                >
                  +
                </button>
                <span>{item.count}</span>
                <button
                  type="button"
                  data-ticket-btn="decrement"
                  className={styles.countBtn}
                  onClick={basketTicketHandler}
                >
                  -
                </button>
              </div>
            </div>
            <div className={styles.costWrap}>
              <b>{t("basket.good.cost")}:</b>
              <p className={`${styles.cost} ${styles.totalCost}`}>
                {usedTicket === undefined &&
                  item.selector === "ticket" &&
                  userObj !== null
                  ? setCostItem(item, userObj).toFixed(0)
                  : (item.price * item.count).toFixed(0)}{" "}
                &#8381;
              </p>
            </div>
          </div>

          {item.insurance_available && (
            <div className={`${styles.insideItem} ${styles.insideItemInsurance}`}>
              <div className={`${styles.location} ${styles.locationInsurance}`}>
                <b>{t("basket.good.add")}:</b>
                <div style={{ marginTop: "5px" }}>
                  {insurancesList.length ? <select
                    className={styles.insuranceSelect}
                    name="insurances"
                    onChange={handleSelectInsurance}
                    value={selectedInsurance}
                  >
                    <option selected value="null">
                      Добавить страховку
                    </option>
                    {insurancesList.map((el) => (
                      <option
                        key={el.public_id}
                        value={JSON.stringify(el)}
                      >
                        {el.name}
                      </option>
                    ))}
                  </select> : null}
                </div>
                <p className={styles.text}>{t("basket.good.insurance")}</p>
              </div>
              <div className={styles.count}>
                <b>{t("basket.good.count")}:</b>

                <div className={styles.text}>
                  {item.insurance && <span>{item.count}</span>}
                  <button
                    onClick={
                      item.insurance
                        ? handleButtonRemoveInsurance
                        : handleButtonAddInsurance
                    }
                    className={`${item.insurance
                      ? styles.deleteInsuranceBtn
                      : styles.addInsuranceBtn
                      }`}
                  >
                    {item.insurance ? "Удалить" : "Добавить"}
                  </button>
                </div>

              </div>

              <div className={styles.costWrap}>
                <p className={styles.cost}>
                  {item.insurance && item.insurance_cost * item.count}
                  {item.insurance && " ₽"}
                </p>
              </div>

            </div>
          )}

          {eventGoods && eventGoods.data.length && item.selector === "ticket"
            ? eventGoods.data.map((product, i) => (
              <OptionalCard
                key={`${product.public_id}${i}`}
                product={product}
                id={`${eventGoods?.id}${product?.public_id}`}
              />
            ))
            : null}
        </div>
        {isPreregistration && item.preReg !== "later" &&

          <div className={styles.ticketForm}>

            <div style={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
              <p className={styles.formText}>Необходимо заполнить обязательные данные участника</p></div>

            <div className={styles.formWrap}>
              <div className={styles.wrapperForm}>
                <Input
                  firstLetterUpperCase
                  error={nameError}
                  handleFieldChange={handleNameChange}
                  handleFocus={handleNameFocus}
                  Logo={ReactLogoName}
                  label={'Ваше имя'}
                  name={'name'}
                  id={'ticket-name'}
                  ref={nameRef}
                  value={name}
                />

                <Input
                  firstLetterUpperCase
                  error={lastNameError}
                  handleFieldChange={handleLastNameChange}
                  handleFocus={handleLastNameFocus}
                  Logo={ReactLogoName}
                  label={'Ваша Фамилия'}
                  name={'lastName'}
                  id={'ticket-lastName'}
                  ref={lastNameRef}
                  value={lastName}
                />

                {isSecondName &&
                  <Input
                    handleFieldChange={handleSecondNameChange}
                    handleFocus={handleSecondNameFocus}
                    error={secondNameError}
                    Logo={ReactLogoName}
                    label={'Ваше Отчество'}
                    name={'secondNname'}
                    id={'ticket-secondName'}
                    placeholder={'Если есть'}
                    ref={secondNameRef}
                    defaultValue={secondName}
                  />}

                <InputPhone
                  country="RU"
                  outsideStyle={styles}
                  international={true}
                  withCountryCallingCode={true}
                  error={isErrors.phone}
                  label={"Введите номер телефона:"}
                  id="ticket-basket-phone"
                  type="tel"
                  name="phone"
                  value={phone}
                  onFocus={handleFocus}
                  onChange={handlePhoneChange}
                  ref={telRef}
                />

                {sizesTshirt?.length > 0 && !item.shirt_unavailable && (
                  <div className={styles.cluster}>
                    <CustomSelect
                      error={isErrors.size}
                      handleSelectChange={setSize}
                      handleFocus={handleSizeResetError}
                      value={size}
                      prefix={"basketsize-select"}
                      styles={styles}
                      title={"Размер футболки"}
                      name={"size"}
                      options={setCount(sizesTshirt, "size")}
                      isOptionDisabled={(option) => option.disabled}
                      placeholder={"Выберите ваш размер"}
                      closeMenuOnScroll={() => false}
                    />
                  </div>
                )}

                {itemRegions && regions?.length ? (
                  <CustomSelect
                    error={isErrors.region}
                    handleSelectChange={setRegion}
                    handleFocus={() => setErrors((prev) => ({ ...prev, region: "" }))}
                    value={region}
                    prefix={"ticket-select"}
                    styles={styles}
                    title={"Регион проживания"}
                    name={"region"}
                    options={filteredRegions}
                    placeholder={"Выберите ваш регион"}
                    closeMenuOnScroll={() => false}
                  />
                ) : null}

                {fields?.length ? fields.map((field) => (
                  <Input
                    key={field.name}
                    handleFocus={() =>
                      handleAdditionalFiledFocus(field.name)
                    }
                    handleFieldChange={handleAdditionalField}
                    error={isErrors[`${field.name}`]}
                    label={field.label}
                    name={field.name}
                    id={`basket-${field.name}`}
                    defaultValue={additionalValue?.[field.name]}
                  />
                )) : null}

                <Datepicker
                  error={isErrors.date}
                  value={valueDate}
                  handleDateChange={handleDateChange}
                  handleDateFocus={handleDateFocus}
                  Logo={DateLogo}
                  label={'День рождения'}
                  id={'birth_date'}
                />

                <div className={styles.radioWrapper}>
                  <p
                    className={styles.genderTitle}

                  >
                    Ваш пол:
                  </p>
                  <ul className={styles.genderList}>
                    <li className={styles.genderItem}>
                      <Radio
                        error={isErrors.gender}
                        ref={radioRef}
                        initialValue={'male'}
                        value={gender}
                        name={item.event_city.public_id}
                        id={`${item.event.public_id}${item.event_city.public_id}${item.format_public_id}`}
                        label={'Мужской'}
                        handleRadioChange={handleRadioChange}
                      />
                    </li>
                    <li className={styles.genderItem}>
                      <Radio
                        error={isErrors.gender}
                        ref={radioRef}
                        initialValue={'female'}
                        value={gender}
                        name={item.event_city.public_id}
                        id={`${item.format_public_id}${item.event_city.public_id}${item.event.public_id}`}
                        label={'Женский'}
                        handleRadioChange={handleRadioChange}
                      />
                    </li>
                  </ul>
                  {isErrors?.gender?.length ? (
                    <Message message={isErrors.gender} styles={styles} />
                  ) : null}
                </div>

                {formatCluster?.length > 0 && (
                  <>
                    <CustomSelect
                      error={isErrors.cluster}
                      handleSelectChange={handleSelectChange}
                      handleFocus={handleClusterResetError}
                      value={cluster}
                      prefix={"cluster-select"}
                      styles={styles}
                      title={"Кластер*"}
                      name={"cluster"}
                      options={formatCluster}
                      placeholder={"Выберите ваш кластер"}
                      closeMenuOnScroll={() => false}
                    />

                    {isLoadFile && <label className={styles.labelFile}>Подтверждение*
                      <span className={`${styles.textFile} ${isErrors.file && styles.labelFileError}`}>
                        Загрузить документ
                        <input className={styles.inputFile}
                          accept="image/pdf, image/jpeg, image/png , image/jpg"
                          type="file"
                          onChange={handleFileChange}
                          name="file"
                          onFocus={handleFileFocus}
                        />
                      </span>
                      {isErrors.file.length ? (
                        <span>{isErrors.file}</span>
                      ) : null}
                      {file && file.name || item.fileName ? (
                        <span style={{ maxWidth: "300px", overflow: "hidden" }}>{file?.name || item.fileName}</span>
                      ) : null}
                    </label>}
                  </>
                )}

                {item.license_file && <label className={styles.labelFile}>Лицензия*
                  <span className={`${styles.textFile} ${isErrors.license && styles.labelFileError}`}>
                    Загрузить документ
                    <input className={styles.inputFile}
                      accept="image/pdf, image/jpeg, image/png , image/jpg"
                      type="file"
                      onChange={handleLicenseChange}
                      name="license"
                      onFocus={handleLicenseFocus}
                    />
                  </span>
                  <span>{isErrors.license}</span>
                  {licenseFile && licenseFile.name ? (
                    <span style={{ maxWidth: "300px", overflow: "hidden" }}>{licenseFile?.name}</span>
                  ) : null}
                </label>}

                {item.select && (
                  <div className={styles.cluster}>
                    <CustomSelect
                      error={isErrors.place}
                      handleSelectChange={setPlace}
                      handleFocus={() => setErrors((prev) => ({ ...prev, place: "" }))}
                      value={place}
                      prefix={"basketsize-select"}
                      styles={styles}
                      title={"Место получения стартового номера"}
                      name={"place"}
                      options={item.select}
                      placeholder={"Место получения стартового номера"}
                      closeMenuOnScroll={() => false}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>}

        {item.notes?.length > 0 ? <div className={styles.row}>
          <ul className={styles.notesWrapper}>
            {item.notes.map((el, i) => (
              <li key={i} className={styles.notes}>
                {el}
              </li>
            ))}
          </ul>
        </div> : null}

        {delivery?.length > 0 && item.delivery ? <div style={{ marginTop: "20px", marginBottom: "20px" }} className={styles.cluster}>
          <CustomSelect
            error={isErrors.deliveryMethod}
            handleSelectChange={setDeliveryMethod}
            handleFocus={() => setErrors((prev) => ({ ...prev, deliveryMethod: "" }))}
            value={deliveryMethod}
            prefix={"basketsize-select"}
            styles={styles}
            title={"Способ доставки"}
            name={"deliveryMethod"}
            options={setCount(delivery, "name", "public_id")}
            placeholder={"Выберите способ доставки"}
            closeMenuOnScroll={() => false}
          />
        </div> : null}
        {item.selector === "insurance" && <InsuranceForm handleChange={handleChange} insurance={item} />}
        <div className={styles.addBlock}>

          <div
            className={`${styles.promocodeBtnWrap}
          ${promocodeError && !!promocodeError.message
                ? styles.promocodeBtnWrapError
                : ``
              }
          ${promocodeApplied ? styles.promocodeBtnWrapCheck : ``}`}
          >
            <SaleLogo className={styles.inputLogo} />
            <input
              className={styles.promocodeField}
              type="text"
              placeholder={t("basket.good.promocode.placeholder")}
              id={item.public_id}
              disabled={
                promocodeApplied ||
                (item.selector === "ticket" &&
                  usedTicket === undefined &&
                  isVipUser) ||
                item.selector === "certificate"
              }
              onChange={() => { }}
              onFocus={promocodeFocusHandler}
              ref={promocodeRef}
              defaultValue={promocode && promocode.code}
            />

            {promocodeError && !!promocodeError.message && (
              <WarnLogo className={styles.checkLogo} />
            )}
            {promocodeApplied && <CheckLogo className={styles.checkLogo} />}

            <button
              disabled={
                (item.selector === "ticket" &&
                  usedTicket === undefined &&
                  isVipUser) ||
                item.selector === "certificate"
              }
              className={styles.promocodeBtn}
              onClick={
                !promocodeApplied ? handlePromocodeCheck : handlePromocodeChange
              }
            >
              {!promocodeApplied
                ? t("basket.good.promocode.btnApply")
                : t("basket.good.promocode.btnChange")}
            </button>

            {promocodeError && !!promocodeError.message ? (
              <Message styles={styles} message={promocodeError.message} />
            ) : null}
          </div>

          <p className={basketStyles.certificate}>
            {t("basket.aside.ref")}
            <span className={basketStyles.certificateHelp}>
              Описание для мед справки
            </span>
          </p>
        </div>
        <button onClick={deleteGoodsHandler} className={styles.deleteItemBtn}>
          {t("basket.good.deleteBtn")}
        </button>

      </li>


    </>
  );
});

export default Goods;
